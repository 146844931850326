import styled from 'styled-components';
import { Box } from 'rebass';

export const InfoBox = styled(Box)`
  padding: 1rem;
  background-color: ${props => props.theme.colors.accent};
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 200ms ease-out;

  &:hover {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  }
`;

export const Highlight = styled.span`
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
`;

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import RoomTypeDetail from "../components/RoomTypeDetail";

const Page = ({ data, location }) => {
  const { wordpressWpMphbRoomType: page, site } = data;
  const { scrollToReservation } = location.state || {};

  return (
    <Layout>
      <Helmet title={`${page.title} | ${site.siteMetadata.title}`} />
      <RoomTypeDetail
        content={page.content}
        categories={page.categories}
        image={page.featured_media.localFile.childImageSharp.fluid}
        imageAlt={page.featured_media.alt_text}
        tags={page.tags}
        slug={page.slug}
        title={page.title}
        excerpt={page.acf.reduced_content || page.excerpt || null}
        date={page.date}
        facilities={page.mphb_room_type_facility}
        gallery={page.acf.photogallery}
        slideshow={page.acf.slideshow}
        map={page.acf.map || null}
        price_from={page.acf.price_from || null}
        minimum_nights={page.acf.minimum_nights || null}
        max_person={page.acf.max_person || null}
        link_to_reservation_iframe={page.acf.link_to_reservation_iframe || null}
        naviURL={page.acf.navi_url || null}
        scrollToReservation={scrollToReservation}
      />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
  query RoomTypeById($id: String!) {
    wordpressWpMphbRoomType(id: { eq: $id }) {
      title
      slug
      excerpt
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        reduced_content
        price_from
        minimum_nights
        max_person
        navi_url
        link_to_reservation_iframe
        map {
          address
          lat
          lng
        }
        photogallery {
          alt_text
          localFile {
            name
            id
            childImageSharp {
              fluid(maxWidth: 1280, maxHeight: 960, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        slideshow {
          alt_text
          localFile {
            name
            id
            childImageSharp {
              fluid(maxWidth: 1680, maxHeight: 1260, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      mphb_room_type_facility {
        name
        wordpress_id
      }
      mphb_adults_capacity
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

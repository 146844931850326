/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Flex } from 'rebass'
import { lighten } from 'polished'
import { Section, WoodSection } from './Section'
import Container from './Container'
import Carousel from './Carousel'
import Card from './Card'
import PrimaryLink from './Buttons/PrimaryLink'
import GhostButton from './Buttons/GhostButton'
import SeznamMapa from './Map'
import Iframe from './Iframe'
import { InfoBox, Highlight } from './InfoBox'

const DarkSection = styled(Section)`
  background-color: ${(props) => props.theme.colors.text};
  background: ${(props) =>
    `linear-gradient(60deg, ${lighten(0, props.theme.colors.text)}, ${lighten(
      0.1,
      props.theme.colors.text,
    )})`};
`

const ContentWrapper = styled.div`
  display: block;
`

const Content = styled.div`
  img {
    max-width: 100%;
  }
`

const FacilitiesList = styled.ul`
  list-style: none;
  padding: 0;
  line-height: 1.75;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid ${(props) => props.theme.colors.accent};
  padding: 1rem 1rem 0.5rem 1rem;

  li {
    transition: transform 150ms ease-in-out;
    margin: 0 1rem 0.5rem 0;
  }

  li:hover {
    transform: translateY(-2px);
    cursor: default;
  }
`

const RoomTypeDetail = ({
  content,
  title,
  excerpt,
  slideshow,
  facilities,
  map,
  slug,
  naviURL,
  price_from: priceFrom,
  minimum_nights: minimumNights,
  max_person: maxPerson,
  link_to_reservation_iframe: linkToReservationIframe,
  scrollToReservation,
}) => {
  const [contentOpened, toggleContentOpened] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && scrollToReservation) {
      const iframeScrollY = document.querySelector('#rezervace').offsetTop
      window.scrollTo(0, iframeScrollY)
    }

    const images = document.querySelectorAll('img')

    images.forEach((image) => {
      image.removeAttribute('width')
      image.removeAttribute('height')
    })
  }, [])

  return (
    <article>
      <Carousel photos={slideshow} />

      <DarkSection>
        <Box mt={[-2, -5]} style={{ zIndex: 3, position: 'relative' }}>
          <Container narrow>
            <ContentWrapper>
              <Card large>
                <Flex alignSelf="center" justifyContent="center">
                  <PrimaryLink large to={`/chaloupky/${slug}/galerie`}>
                    Galerie
                  </PrimaryLink>
                </Flex>

                <h1>{title}</h1>

                {facilities !== null && (
                  <FacilitiesList>
                    {facilities.map((facility) => (
                      <li key={facility.wordpress_id}>{facility.name}</li>
                    ))}
                  </FacilitiesList>
                )}

                <Flex
                  width="100%"
                  flexDirection={['column', 'row']}
                  justifyContent="space-between"
                >
                  {priceFrom && (
                    <InfoBox>
                      Cena <Highlight>{`${priceFrom}/noc`}</Highlight>
                    </InfoBox>
                  )}
                  {minimumNights && (
                    <InfoBox>
                      Minimální počet nocí{' '}
                      <Highlight>{minimumNights}</Highlight>{' '}
                    </InfoBox>
                  )}
                  {maxPerson && (
                    <InfoBox>
                      Vhodné až pro <Highlight>{maxPerson}</Highlight> osob
                    </InfoBox>
                  )}
                </Flex>

                {excerpt && (
                  <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                )}

                <GhostButton
                  style={{ display: contentOpened ? 'none' : 'block' }}
                  onClick={() => toggleContentOpened(true)}
                >
                  Číst více...
                </GhostButton>

                <Content style={{ display: contentOpened ? 'block' : 'none' }}>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </Content>
              </Card>
            </ContentWrapper>
          </Container>
        </Box>
      </DarkSection>

      {map && (
        <SeznamMapa
          center={{ lng: map.lng, lat: map.lat }}
          markers={[{ lat: Number(map.lat), lng: Number(map.lng) }]}
          lng={map.lng}
          lat={map.lat}
          zoom={14}
          containerStyle={{
            height: '50vh',
            width: '100vw',
          }}
          popupText={map.address}
          naviURL={naviURL || null}
        />
      )}

      {linkToReservationIframe && (
        <WoodSection id="rezervace">
          <Container narrow>
            <Card large title={`Rezervace "${title}"`} titleCentered>
              <Iframe src={linkToReservationIframe} />
            </Card>
          </Container>
        </WoodSection>
      )}
    </article>
  )
}

export default RoomTypeDetail
